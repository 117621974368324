
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        page: String,
        hasLogin: Boolean,
        activeTab: Number,
        changeTab: Function,
        // tabs: Array,
    },
})
export default class Submenu extends Vue {
    private tabs = ['公开问题', '我的提问'];
    backFaq() {
        this.$router.push('./faq');
    }
    clickAskQues() {
        this.$router.push('./question');
    }
}
