
import { Options, Vue } from 'vue-class-component';
import { Store, useStore } from 'vuex';
import { ElInput, ElButton } from 'element-plus';
import GrayHeader from '@/components/grayHeader.vue';
import Submenu from '@/components/faq/submenu.vue';
import { getQueryComArQa, getQueryArQa } from '@/api/index';
import { trim } from 'lodash';

@Options({
    components: {
        GrayHeader,
        Submenu,
        ElInput,
        ElButton,
    },
})
export default class Faq extends Vue {
    private store: Store<any> = useStore();
    private searchKey = '';
    private tabs = ['公开问题', '我的提问'];
    private categories: Array<String> = ['全部问题', '大赛规则相关', '报名相关', '队伍相关', '赛题相关', '平台使用相关', '提交相关', '成绩相关', '其它'];
    private pageSize = 5;
    private pageState: {
        activeTab: number;
        activeCate: number;
        showMoreButton: boolean;
        quesPage: number;
        hasQues: boolean;
        quesList: Array<Object>;
        allowClick: boolean;
    } = {
        activeTab: 0,
        activeCate: 0,
        showMoreButton: false,
        quesPage: 1,
        hasQues: true,
        quesList: [],
        allowClick: true,
    };

    get hasLogin() {
        return this.store.state.loginStatus.haslogin;
    }

    async changeTab(index: number) {
        if (!this.pageState.allowClick) return;
        this.pageState = {
            ...this.pageState,
            activeTab: index,
            activeCate: 0,
            showMoreButton: false,
            quesPage: 1,
            hasQues: true,
            quesList: [],
        };
        await this.clickMore();
    }

    getConditions(): Array<Object> {
        const res: any = [];
        const trimSearchKey = this.searchKey.trim();
        if (this.pageState.activeCate !== 0) {
            res.push({ Field: 'Theme', opt: 0, Value: this.categories[this.pageState.activeCate] });
        }
        if (trimSearchKey !== '') {
            res.push({ Field: 'Title', opt: 6, Value: trimSearchKey });
        }
        return res;
    }

    async getQuesList(curPage: number, pageSize: number, conditions?: Array<Object>) {
        let res = [];
        if (this.pageState.activeTab === 0) {
            res = await getQueryComArQa(curPage, pageSize, conditions);
        } else if (this.pageState.activeTab === 1) {
            res = await getQueryArQa(curPage, pageSize, conditions);
        }
        if (!res) return;

        const { Code, Data, Page } = res;
        if (!Code && Data) {
            this.pageState.quesList.push(...Data);
            if (Data.length === 0) {
                this.pageState.hasQues = false;
            }
            if (this.pageState.quesList.length >= Page.TotalCount) {
                this.pageState.showMoreButton = false;
            } else {
                this.pageState.showMoreButton = true;
            }
        }
    }

    async clickMore() {
        if (!this.pageState.allowClick) return;
        this.pageState.allowClick = false;
        const conditions = this.getConditions();
        if (conditions.length === 0) {
            await this.getQuesList(this.pageState.quesPage, this.pageSize);
        } else {
            await this.getQuesList(this.pageState.quesPage, this.pageSize, conditions);
        }
        this.pageState.allowClick = true;
        this.pageState.quesPage += 1;
    }

    async clickCategory(index: number) {
        if (!this.pageState.allowClick) return;

        let activeCate = 0;
        if (index === this.pageState.activeCate) {
            activeCate = 0;
        } else {
            activeCate = index;
        }

        this.pageState = {
            ...this.pageState,
            activeCate,
            quesPage: 1,
            hasQues: true,
            quesList: [],
            showMoreButton: false,
        };

        await this.clickMore();
    }

    async searchQues() {
        this.pageState = {
            ...this.pageState,
            showMoreButton: false,
            hasQues: true,
            quesPage: 1,
            quesList: [],
        };
        await this.clickMore();
    }

    async mounted() {
        this.clickMore();
    }
}
